import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private toastCtrl: ToastController) {}

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      // Handle HTTP errors here
      console.error('An error occurred:', error.error.message);
    } else {
      // Handle client errors here
      console.error('An error occurred:', error.message);
    }

    // Display a toast with the error message
    this.toastCtrl.create({
      message: error.message,
      duration: 10000,
      position: 'bottom'
    }).then(toast => toast.present());
  }
}